@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "~ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500&display=swap);

#modal-root {
  position: relative;
  z-index: 999;
}

.ag-theme-alpine .ag-header-cell-label {
  font-weight: bold;
  justify-content: center;
}

.ag-theme-alpine {
  @include ag-theme-alpine((
          data-color:#2d2d2d,
          header-background-color:#dee1e2,
          header-foreground-color:#2d2d2d,
          background-color:white,
          header-height: 52px,
          row-height:52px,
          borders:true,
          border-color:transparent,
    //cell-horizontal-border:solid #bec0c2,
    //header-column-separator: true,
    //header-column-separator-color:#bec0c2,
          header-text-style:bold
  ));
}

/*
  scrollbar css
*/
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.ag-center-cols-viewport{
  overflow: hidden;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: color 9999s ease-out, background-color 9999s ease-out;
  transition-delay: 9999s;
}

html {
  --gray-3: #8c8e90;
  --black: #2d2d2d;
  --blue: #4191ff;
  --deep-blue: #1450a5;
  --sky: #85b8ff;
  --red: #eb5757;
  --gray-4: #bec0c2;
  --white: #ffffff;
  --gray-5: #dee1e2;
  --pale-grey: #fafbfc;
}
